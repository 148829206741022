
import {useRoute, useRouter} from 'vue-router'
import HeaderTit from '@/components/HeaderTit.vue'
import sign from '@/components/sign.vue'
import AxiosAdapter from '../request/AxiosAdapter'
import {getRecord, getRecordId} from '../commons/Utils'
import {defineComponent, getCurrentInstance, h, onMounted, reactive, ref, toRefs} from 'vue'
import {emailReg, mobileReg} from '../commons/unit'
import {throwCustomFunction} from '../commons/ErrorHandle'
import signature from './signature.vue'
import {ElMessage, ElMessageBox} from 'element-plus'

export default defineComponent({
  name: 'Confirmation',
  components: {
    HeaderTit,
    sign,
    signature
  },
  setup() {
    const proxy: any = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const provinceOptions: any = reactive([])
    const cityOptions: any = reactive([])
    const districtOptions: any = reactive([])
    const isHavingImg = ref(false)
    const confirm = ref(true)
    const signSrc = ref('')
    const sign = ref()
    const signShow = ref(false)
    const receiverName = ref()
    const signImgStr = ref()
    const signMobile = ref()
    const signPc = ref()
    const id = ref('')
    const institutionId = ref()
    const addressList = ref([])
    const selectedAddressId = ref([])
    const caseUserId = ref()
    const fileType = ref()
    const fileState = ref()
    const fileId = ref()

    const state = reactive({
      caseCause: '',
      caseNumberWord: '',
      caseUserName: '',
      identityType: '',
      identityNumber: '',
      courtName: '',
      judgeName: ''
    })

    function getClientWidthFun() {
      return document.documentElement.clientWidth
    }

    const ruleForm = reactive({
      addressId: '',
      selectedAddressId: '',
      electronicId: '',
      mobilephone: '',
      mobilephone2: '',
      postalCode: '',
      detailAddress: '',
      email: '',
      email2: '',
      relationType: '1',
      provinceCode: '',
      provinceName: '',
      cityCode: '',
      cityName: '',
      districtCode: '',
      districtName: '',
      signImgStr: '',
      isRemotePull: false,
      ignoreAreaValidate: false
    })

    onMounted(() => {
      if (route.query.institutionId) {
        institutionId.value = route.query.institutionId
      }
      if (route.query.receiverName) {
        receiverName.value = route.query.receiverName
      }
    })
    const rules = reactive({
      mobilephone: [
        {
          required: true,
          message: '请输入正确手机号',
          trigger: 'blur',
          pattern: mobileReg
        }
      ],
      mobilephone2: [
        {
          required: false,
          message: '请输入正确手机号',
          trigger: 'blur',
          pattern: mobileReg
        }
      ],
      provinceCode: [
        {
          required:
              !(
                  route.query.institutionId == '450521' ||
                  route.query.institutionId == '340202' ||
                  route.query.institutionId == '320111' ||
                  route.query.institutionId == '340222' ||
                  route.query.institutionId == '340223' ||
                  route.query.institutionId == '350582'),
          message: '请选择所在地区',
          trigger: 'change'
        } || {
          required:
              !(
                  route.query.institutionId == '340202' ||
                  route.query.institutionId == '320111' ||
                  route.query.institutionId == '340222' ||
                  route.query.institutionId == '340223' ||
                  route.query.institutionId == '350582'),
          message: '请选择所在地区',
          trigger: 'change'
        }
      ],
      detailAddress: [
        {
          required:
              route.query.institutionId == '340202' ||
              route.query.institutionId == '320111' ||
              route.query.institutionId == '340222' ||
              route.query.institutionId == '340223' ||
              route.query.institutionId == '350582'
                  ? false
                  : true,
          message: '请输入确认送达地址',
          trigger: 'blur'
        }
      ],
      email: [
        {
          required: false,
          message: '请输入正确电子邮箱',
          trigger: 'blur',
          pattern: emailReg
        }
      ],
      email2: [
        {
          required: false,
          message: '请输入正确电子邮箱',
          trigger: 'blur',
          pattern: emailReg
        }
      ]
    })

    const pramesDetail = reactive({
      byElectronicId: route.query.id
    })

    const selectAddress = (res: any) => {
      return (
          res == '340202' ||
          res == '320111' ||
          res == '340222' ||
          res == '340223' ||
          res == '350582'
      )
    }
    const getProvinceList = async () => {
      const data = await AxiosAdapter.get({url: '/city/list'})
      provinceOptions.values = data
    }
    const getCityList = async (code: any) => {
      cityOptions.values = []
      districtOptions.values = []
      ruleForm.cityCode = ''
      ruleForm.districtCode = ''
      if (code) {
        const data = await AxiosAdapter.get({
          url: '/city/list',
          data: {parentCode: code}
        })
        cityOptions.values = data
      }
    }
    const getDistrictList = async (code: any) => {
      districtOptions.values = []
      ruleForm.districtCode = ''
      if (code) {
        const data = await AxiosAdapter.get({
          url: '/city/list',
          data: {parentCode: code}
        })
        districtOptions.values = data
      }
    }
    const getConfirmAddressFun = async (id: string) => {
      AxiosAdapter.get<any, any>({
        url: '/webElectronic/getConfirmAddress',
        data: {byElectronicId: id}
      })
          .then(function (res) {
            const data = res
            state.caseUserName = data.caseUserName
            state.identityType = data.identityType
            state.identityNumber = data.identityNumber
            caseUserId.value = data.caseUserId
            state.courtName = data.courtName
            state.caseCause = data.caseCause
            state.caseNumberWord = data.caseNumberWord
            state.judgeName = data.judgeName
            return data
          })
          .then((data) => {
            if (data.signImgStr) {
              signSrc.value = process.env.VUE_APP_BASE_API + data.signImgStr
              isHavingImg.value = true
            }
            return data
          })
          .then(async (data) => {
            if (data.provinceCode) {
              await getCityList(data.provinceCode)
            }
            if (data.cityCode) {
              await getDistrictList(data.cityCode)
            }
            addressList.value = data.addressList
            ruleForm.electronicId = data.electronicId
            ruleForm.addressId = data.addressId
            ruleForm.mobilephone = data.mobilephone
            ruleForm.mobilephone2 = data.mobilephone2
            ruleForm.postalCode = data.postalCode
            if (institutionId.value == 450521) {
              ruleForm.provinceName = data.provinceName
              ruleForm.cityName = data.cityName
              ruleForm.districtName = data.districtName
            } else {
              ruleForm.provinceCode = data.provinceCode
              ruleForm.cityCode = data.cityCode
              ruleForm.districtCode = data.districtCode
            }


            ruleForm.detailAddress = data.detailAddress
            ruleForm.email = data.email
            ruleForm.email2 = data.email2
            ruleForm.relationType = data.relationType
          })
    }
    const getFileDetail = async (item: any) => {
      await AxiosAdapter.get({
        url: 'FileDetail/directSignAddressInfo/' + item
      })
          .then((res: any) => {
            state.caseUserName = res.userName
            state.courtName = res.courtName
            state.caseCause = res.caseCauseName
            state.caseNumberWord = res.caseNumberWord
            institutionId.value = res.institutionId
            id.value = res.id
            route.query.id = res.id
            route.query.caseNumberWord = res.caseNumberWord
            getConfirmAddressFun(id.value)
          })
          .catch((error) => {
            console.log(error)
          })
    }
    const signShowFun = () => {
      signShow.value = true
    }
    const fileTypeFun = async () => {
      const urlStr = window.location.href
      const index1 = urlStr.indexOf("?") + 1
      const index2 = urlStr.indexOf("=")
      fileType.value = urlStr.substring(index1, index2)
      if (fileType.value == 'id') {
        if (route.query.id && typeof route.query.id === 'string') {
          id.value = route.query.id
          getConfirmAddressFun(id.value)
        }
      } else {
        const index = urlStr.indexOf("=") + 1;
        fileId.value = urlStr.substring(index)
        await AxiosAdapter.get({
          url: 'FileDetail/directStatus/' + fileId.value
        })
            .then((res: any) => {
              fileState.value = res
              if (fileState.value.status === 0) {
                // const urlStr = window.location.href
                // const index1 = urlStr.indexOf("?") + 1
                // const index2 = urlStr.indexOf("=")
                // fileType.value = urlStr.substring(index1, index2)
                // if (fileType.value == 'id') {

                // } else {
                getFileDetail(fileId.value)
                // }

              } else {
                const num = fileId.value.indexOf("_")
                const result = fileId.value.substring(0, num);
                router.push({
                  path: '/documents-detail',
                  query: {
                    id: result,
                    caseNumberWord: fileState.value.caseNumberWord
                  }
                })
              }
            })
            .catch((error) => {
              console.log(error)
            })
      }

      // getFileDetail(id)
    }
    onMounted(() => {
      fileTypeFun()
    })

    const getThirdPartyAddressFun = async (sucType: any, sucData: any) => {
      if (sucType == 200) {
        ElMessageBox({
          title: '获取结果',
          customClass: 'message-box',
          center: true,
          message: h('p', null, [
            h(
                'div',
                {style: 'text-align: center'},
                `${sucData.detailAddress}`
            )
          ]),
          showCancelButton: true,
          confirmButtonText: '继续',
          cancelButtonText: '返回'
        })
            .then(function () {
              ruleForm.selectedAddressId = sucData.id
              ruleForm.isRemotePull = true
              AxiosAdapter.post({
                url: '/webElectronic/electronicSaveAddress',
                data: ruleForm
              })
                  .then(function (res) {
                    if (fileType.value == 'id') {
                      router.push({
                        path: '/documents-detail',
                        query: {
                          id: route.query.id,
                          caseNumberWord: route.query.caseNumberWord
                        }
                      })
                    } else {
                      router.replace({
                        path: '/documents-detail',
                        query: {
                          id: route.query.id,
                          caseNumberWord: route.query.caseNumberWord
                        }
                      })
                    }

                  })
                  .catch(function (err) {
                    ElMessage.error({
                      type: 'error',
                      message: err,
                      duration: 3000
                    })
                    ruleForm.selectedAddressId = ''
                    ruleForm.isRemotePull = false
                  })
            })
            .catch(() => {
              ruleForm.selectedAddressId = ''
              ruleForm.isRemotePull = false
            })
      }
      if (sucType == -1) {
        ElMessageBox.alert('这是一段内容', '标题名称', {
          title: '获取结果',
          confirmButtonClass: 'sucTypeAlert',
          customClass: 'message-box',
          center: true,
          confirmButtonText: '返回',

          message: h('p', null, [
            h(
                'div',
                {style: 'color:red'},
                '地址获取失败，请核对您的姓名及证件号码'
            ),
            h('div', {style: 'color:red'}, '或填写您的有效地址')
          ])
        })
      }
    }
    const submit = async () => {
      if (!id.value) {
        throwCustomFunction('id丢失')
      }
      if (selectAddress(institutionId.value)) {
        if (selectedAddressId.value.length == 0) {
          rules.provinceCode[0].required = true
          rules.detailAddress[0].required = true
        } else {
          rules.provinceCode[0].required = false
          rules.detailAddress[0].required = false
        }
      }
      proxy.proxy.$refs.ruleForm1.validate((valid: any, object: any) => {
        if (valid) {
          if (getClientWidthFun() >= 768) {
            if (
                !isHavingImg.value &&
                (!signPc.value.linen || signPc.value.linen.length < 60)
            ) {
              ElMessage.error({
                type: 'error',
                message: '签名不合法pc',
                duration: 3000
              })

              throwCustomFunction('签名不合法pc')
            }
          }

          if (getClientWidthFun() < 768) {
            if (!isHavingImg.value && !signImgStr.value) {
              // ElMessage.error({
              //   type: 'error',
              //   message: '签名不合法mobile',
              //   duration: 3000
              // })
              ElMessage.error({
                type: 'error',
                message: '签名不合法mobile',
                duration: 3000
              })
              throwCustomFunction('签名不合法mobile')
            }
          }
          if (!confirm.value) {
            ElMessage.error({
              type: 'error',
              message: '请确认送达内容',
              duration: 3000
            })
            throwCustomFunction('请确认送达内容')
          }
          ruleForm.signImgStr = !isHavingImg.value
              ? getClientWidthFun() < 768
                  ? signImgStr.value
                  : signPc.value.submit(80, 50)
              : ''
          // AxiosAdapter.post({
          //   url: '/webElectronic/recordBase64Log',
          //   data: {logBase64: ruleForm.signImgStr}
          // })
          //340202=芜湖市镜湖区人民法院  320111 浦口  shenxiaohong
          if (selectAddress(institutionId.value)) {
            if (selectedAddressId.value.length == 0 && !ruleForm.cityCode) {
              //  throwCustomFunction("请选择地址");
              ElMessageBox({
                title: '提示',
                customClass: 'message-box',
                center: true,
                message: h('p', null, [
                  h(
                      'div',
                      {style: 'text-align: center'},
                      '您未勾选也未填写有效地址，'
                  ),
                  h(
                      'div',
                      {style: 'text-align: center'},
                      '法院将使用您的户籍地/注册地'
                  )
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
              }).then(() => {
                AxiosAdapter.get<any, any>({
                  url: '/webElectronic/getThirdPartyAddress',
                  data: {caseUserId: caseUserId.value, repairSource: 2}
                })
                    // .then(function (res) {})
                    .then((res) => {
                      if (
                          !res.caseUserAddress.detailAddress ||
                          !res.caseUserAddress.id
                      ) {
                        getThirdPartyAddressFun(-1, null)
                        return
                      }
                      getThirdPartyAddressFun(200, res.caseUserAddress)
                    })
                    .catch((error) => {
                      getThirdPartyAddressFun(-1, null)
                      // ElMessage.error(error || "error");
                    })
              })
            } else {
              if (selectedAddressId.value.length > 0) {
                ruleForm.selectedAddressId = selectedAddressId.value.toString()
              } else {
                ruleForm.selectedAddressId = ''
                // if (!ruleForm.provinceCode) {
                //   throwCustomFunction("请选择省");
                // }
                if (!ruleForm.cityCode) {
                  throwCustomFunction('请选择所在地区')
                }
                if (!ruleForm.detailAddress) {
                  throwCustomFunction('请输入确认送达地址')
                }
              }
              AxiosAdapter.post({
                url: '/webElectronic/electronicSaveAddress',
                data: ruleForm
              })
                  .then(function () {
                    if (fileType.value == 'id') {
                      router.push({
                        path: '/documents-detail',
                        query: {
                          id: route.query.id,
                          caseNumberWord: route.query.caseNumberWord
                        }
                      })
                    } else {
                      router.replace({
                        path: '/documents-detail',
                        query: {
                          id: route.query.id,
                          caseNumberWord: route.query.caseNumberWord
                        }
                      })
                    }
                  })
                  .catch(function (val) {
                    ElMessage.error({
                      type: 'error',
                      message: val,
                      duration: 3000
                    })
                  })
            }
          } else {
            // 合浦法院不使用下拉框选择地区
            if (institutionId.value == 450521) {
              ruleForm.ignoreAreaValidate = true
            }

            AxiosAdapter.post({
              url: '/webElectronic/electronicSaveAddress',
              data: ruleForm
            })
                .then(function () {
                  router.push({
                    path: '/documents-detail',
                    query: {
                      id: route.query.id,
                      caseNumberWord: route.query.caseNumberWord
                    }
                  })
                })
                .catch(function (val) {
                  ElMessage.error({
                    type: 'error',
                    message: val,
                    duration: 3000
                  })
                })
          }
        } else {
          for (const i in object) {
            let dom: any = proxy.proxy.$refs[i]
            if (Object.prototype.toString.call(dom) !== '[object Object]') {
              //这里是针对遍历的情况（多个输入框），取值为数组
              dom = dom[0]
            }

            dom.$el.scrollIntoView({
              //滚动到指定节点
              block: 'center', //值有start,center,end，nearest，当前显示在视图区域中间
              behavior: 'smooth' //值有auto、instant,smooth，缓动动画（当前是慢速的）
            })

            if (getClientWidthFun() >= 768) {
              ElMessage.error({
                type: 'error',
                message: object[i][0].message,
                duration: 3000
              })
            }

            break
          }
        }
      })
    }

    //sucType==200 成功   sucType=-1失败

    //签名回退
    const signreturnEvent = () => {
      signShow.value = false
    }

    //获取签名base64
    const signEvent = (e: any) => {
      isHavingImg.value = false
      signShow.value = false
      signImgStr.value = e
    }

    const clearFun = async () => {
      signPc.value.clear()
    }
    getProvinceList()
    getRecord('进入地址确认书页面', true)
    getRecordId(route.query.id, '进入地址确认书页面', true)

    return {
      rules,
      submit,
      ruleForm,
      provinceOptions,
      cityOptions,
      districtOptions,
      selectAddress,
      getProvinceList,
      getCityList,
      getDistrictList,
      isHavingImg,
      signSrc,
      clearFun,
      sign,
      signMobile,
      signPc,
      confirm,
      pramesDetail,
      id,
      getClientWidthFun,
      ...toRefs(state),
      signShow,
      receiverName,
      signEvent,
      signImgStr,
      signreturnEvent,
      signShowFun,
      institutionId,
      addressList,
      selectedAddressId,
      caseUserId,
      getThirdPartyAddressFun,
      getFileDetail
    }
  }
})
